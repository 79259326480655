import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Join Us at the Bluenose 5K 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/Purple-Cow-5k-race.png"
          alt="Purple Cow Internet - Picking the right plan"
          /> 
            <br></br>
            <br></br>
            <h1>Join us at the Bluenose 5K</h1>
            <p>We are thrilled to announce that Purple Cow Internet is a proud sponsor of this year’s Bluenose 5K, the most popular running event in Nova Scotia! As a company, we champion physical activity and community engagement, and sponsoring the Bluenose 5K aligns perfectly with our values.</p>

            <p>I, Bradley Farquhar, will also be participating in the marathon to show our support and commitment to the cause. It’s an excellent opportunity for us to connect with the community and inspire others to embrace a healthy, active lifestyle.</p>

            <p>The event, affectionately known as the Purple Cow 5KM, takes place on Saturday morning May 18th and kicks off a weekend of races. It offers everyone a unique chance to explore the scenic city of Halifax from a fresh perspective, running through its historic streets and waterfront beauty.</p>

            <p>The Bluenose Marathon touches the lives of many within our community and sets a stellar example of the spirit we aim to foster. It encourages people to set personal goals and rise to meet them, embodying the determination and grit we so often celebrate at Purple Cow Internet.</p>

            <p>We invite everyone to join us in this celebration of health, community, and perseverance. Whether you're running, walking, or cheering, your presence amplifies the incredible impact of the Bluenose 5K. Let’s lace up our running shoes, set ambitious goals, and make a positive stride together towards a healthier community.</p>

            <p><a href="https://raceroster.com/events/2024/81415/emera-blue-nose-marathon?mc_cid=b6b5b84f86&mc_eid=e7fda21aa5">Register Today!</a></p>


            <p>See you at the starting line!</p>
            
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
